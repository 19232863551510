:root {
  // COLORS
  --colorBackground: #efeeec;
  --colorBlack: #222;
  --colorLightBlack: #444;
  --colorLightestBlack: #c4c4c4;
  --colorLightWhite: #ffefff;
  --colorOrange: #ff580a;
  --colorLightBlue: #00d1ce;
  --colorPink: #f1dfd5;
  --colorRed: #d10714;

  // DEBUG
  --sample: rgba(214, 29, 29, 0.5);

  // LAYOUT
  --fontFamily: 'AE', sans-serif;

  .blockImportant {
    overflow-y: hidden !important;
    a {
      display: block !important;
    }
  }
  .button-container {
    @media screen and (max-width: 1368px) {
      width: 100%;
    }
  }
  .btn.lightBlue {
    background-color: var(--colorLightBlue);
    color: #fff;
    font-weight: 500;
    margin-bottom: 1.5rem;
    max-width: 250px;
    margin-right: 1.5rem;
    display: flex;
    font-size: 0.8rem;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: var(--colorLightBlue);
      color: #fff;
      filter: saturate(0.7);
    }
    &:disabled {
      filter: opacity(0.5);
      background-color: var(--colorLightBlue);
      color: #fff;
    }
  }
  .btn.delete {
    background-color: var(--colorRed);
    color: #fff;
    font-weight: 500;
    max-width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: var(--colorRed);
      color: #fff;
      filter: saturate(0.7);
    }
    &:disabled {
      filter: opacity(0.5);
      background-color: var(--colorRed);
      color: #fff;
    }
    svg {
      width: 16px;
      height: 16px;
      margin-bottom: 6px;
    }
  }
}
