.back-icon {
  cursor: pointer;
  margin-bottom: 1rem;
  color: rgb(84, 83, 83);
  &:hover {
    color: #00d1ce;
  }
}

a {
  text-decoration: none;
}

.login-btn {
  background-color: #00d1ce;
  color: #fff !important;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
  padding: 12px;
  &:hover {
    filter: saturate(0.7);
  }
}

.card-header {
  margin-top: -1px;
  background-color: #00d1ce;
  color: #fff !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.input-group-prepend {
  margin-right: -3px;
}
.custom-select {
  padding-left: 4px;
  padding-right: 8px;
}

.error-message {
  color: red;
  padding-bottom: 1rem;
}

.table-responsive {
  margin-bottom: 24px;
}
// * {
//   scrollbar-width: thin !important;
//   scrollbar-color: var(--blue) #f0f0f0;
//   &::-webkit-scrollbar {
//     width: 0.5rem;
//   }
//   &::-webkit-scrollbar-thumb {
//     background: green;
//   }
//   &::-webkit-scrollbar-track {
//     background: red;
//   }
// }

.sidebar-nav {
  scrollbar-width: auto !important;
  scrollbar-color: #303c54 #3c4b64 !important;
  &::-webkit-scrollbar {
    width: 1rem;
  }
  &::-webkit-scrollbar-thumb {
    background: #303c54 !important;
  }
  &::-webkit-scrollbar-track {
    background: #3c4b64 !important;
  }
}

.table-transactions {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 1rem;

  thead,
  th,
  tr,
  td {
    padding: 8px;
  }

  tr,
  thead {
    border-bottom: 1px solid #ccc;
  }

  tbody {
    display: block;
    max-height: 300px;
    overflow-y: scroll;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed; /* even columns width , fix width of table too*/
  }
  thead {
    width: calc(100% - 1em); /* scrollbar is average 1em/16px width, remove it from thead width */
  }
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 2px auto;
  font-size: 3px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #00d1ce;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
